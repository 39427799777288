$prime-color: #ffc20a;
$bg-dark: #252525;

$text-color1: #212121;
$text-color2: #828282;
$text-color3: #202d50;
$text-color4: #fdfdff;
$text-color5: #ffffff;

$link-color: #5091cd;

.cms-webview-wrapper {
  scroll-behavior: smooth;
  .cms-preview-container {
    .cms-pagination {
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: space-between;
      .filled {
        height: 4px;
        background-color: $prime-color;
        border-radius: 30px;
        cursor: pointer;
      }
      .unfilled {
        cursor: pointer;
        height: 4px;
        background-color: #d8e1e5;
        border-radius: 30px;
      }
    }
    .cms-unit-details {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      .cms-unit-details-wrapper {
        flex: 1;
        min-width: 1px;
      }
      .unit-title {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.5rem;
        color: $text-color1;
      }
      .unit-position {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $text-color2;
      }
    }
    .button-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: auto;
    }
    .cms-title {
      width: 100%;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: $text-color1;
      margin-bottom: 0.625rem;
    }
    .cms-subtitle {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $text-color2;
      width: 100%;
      margin-bottom: 0.5rem;
    }
    .cms-media-label {
      margin-bottom: 0.5rem;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $text-color1;
    }
    .cms-picture {
      width: 100%;
      margin-bottom: 1.25rem;
      img {
        border-radius: 8px;
        width: 100%;
        object-fit: contain;
      }
    }
    .cms-video {
      margin-bottom: 1rem;
    }
    .cms-audio {
      margin-bottom: 1rem;
      .education-audio {
        max-height: 50px;
      }
    }
    .cms-text {
      width: 100%;
      color: $text-color3;
      margin-bottom: 1rem;
      font-weight: 500;
    }
    .cms-resources {
      margin-bottom: 1rem;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      a {
        color: $link-color;
        word-break: break-all;
      }
      a:hover {
        border-bottom: 1px solid $link-color;
      }
      .link {
        color: $link-color;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .cms-question {
      .question-container {
        margin-bottom: 1rem;
        .question-title {
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.5rem;
          color: $text-color1;
          margin-bottom: 1rem;
        }
      }
      .question-image {
        border-radius: 8px;
        width: 100%;
        object-fit: contain;
        margin-bottom: 1rem;
      }
      .question-choices {
        display: flex;
        flex-direction: column;
        .question-choice-image {
          width: 30px;
          height: 30px;
          object-fit: contain;
          margin-right: 12px;
        }
        .question-choice {
          display: flex;
          align-items: center;

          border: 1px solid rgba(121, 122, 130, 0.3);
          border-radius: 8px;

          width: 100%;
          padding: 16px 12px;

          cursor: pointer;
          margin-bottom: 1rem;

          font-weight: 500;
          font-size: 1.125rem;
          line-height: 1.375rem;
          color: #000000;

          &.selected {
            border: 1px solid $link-color;
            background: rgba(80, 145, 205, 0.06);
            color: #5091cd;
          }

          .aggregate {
            margin-left: auto;
          }
        }
      }
      .compulsory {
        font-style: italic;
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 400;
        color: $text-color1;
        margin-bottom: 1rem;
      }
      .explanation {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        border-radius: 8px;
        margin-bottom: 1rem;
        padding: 1rem;
        &.correct {
          color: #3d8042;
          background: rgba(61, 128, 66, 0.06);
          border: 1px solid #3d8042;
        }
        &.wrong {
          color: #ff6c64;
          background: rgba(255, 108, 100, 0.06);
          border: 1px solid #ff6c64;
        }
      }
      .slider-marks {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        .slider-mark {
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 500;
          max-width: 30%;
          color: $text-color1;
          &.selected {
            font-weight: 600;
          }
        }
      }
    }
  }
  .cms-highlight {
    background: #22de99;
    border-radius: 8px;
    // color: white;
    padding: 2px;
    position: relative;
    cursor: pointer;
    .highlight-text {
      z-index: 10;
      background: #22de99;
      border-radius: 8px;
      padding: 14px 20px;
      position: fixed;
      left: 16px;
      max-width: calc(100vw - 32px);
      .highlight-header {
        display: flex;
        min-width: 250px;
      }
      .highlight-close {
        margin-left: auto;
        height: 16px;
        width: 16px;
        cursor: pointer;
      }
      .title {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5rem;
      }
      .description {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }
  }
}

.cms-webview-wrapper.dark {
  .cms-preview-container {
    background-color: $bg-dark;
    .cms-pagination {
      .unfilled {
        background: $text-color5;
        opacity: 0.2;
      }
    }
    .cms-unit-details {
      .unit-title {
        color: $text-color4;
      }
      .unit-position {
        color: $text-color4;
        opacity: 0.8;
      }
    }
    .cms-title {
      color: $text-color4;
    }
    .cms-subtitle {
      color: $text-color4;
      opacity: 0.7;
    }
    .cms-media-label {
      color: $text-color4;
    }
    .cms-text {
      color: $text-color5;
    }
    .cms-question {
      .question-container {
        .question-title {
          color: $text-color4;
        }
      }
      .question-choices {
        .question-choice {
          color: $text-color4;
          background-color: #2e2e2e;

          &.selected {
            background: rgba(80, 145, 205, 0.06);
            color: $link-color;
          }
        }
      }
      .compulsory {
        color: $text-color4;
      }
      .explanation {
        &.correct {
          background: rgba(61, 128, 66, 0.06);
        }
        &.wrong {
          background: rgba(255, 108, 100, 0.06);
        }
      }
      .slider-marks {
        .slider-mark {
          color: $text-color4;
        }
      }
    }
  }
}

.cms-webview-wrapper.preview {
  .cms-preview-container {
    .cms-highlight {
      .highlight-text {
        max-width: 328px;
      }
    }
  }
}

.cms-webview-wrapper {
  .cms-preview-container {
    .cms-text {
      p {
        margin: 0;
      }
      font-size: 1rem;
      .ql-size-12px {
        font-size: 0.75rem;
      }
      .ql-size-14px {
        font-size: 0.875rem;
      }
      .ql-size-16px {
        font-size: 1rem;
      }
      .ql-size-18px {
        font-size: 1.125rem;
      }
      .ql-size-20px {
        font-size: 1.25rem;
      }
      .ql-size-24px {
        font-size: 1.5rem;
      }
      .ql-size-28px {
        font-size: 1.75rem;
      }
      .ql-size-36px {
        font-size: 2.25rem;
      }
      .ql-indent-1:not(.ql-direction-rtl) {
        padding-left: 3em;
      }
      li.ql-indent-1:not(.ql-direction-rtl) {
        padding-left: 4.5em;
      }
      .ql-indent-2:not(.ql-direction-rtl) {
        padding-left: 6em;
      }
      li.ql-indent-2:not(.ql-direction-rtl) {
        padding-left: 7.5em;
      }
      .ql-indent-3:not(.ql-direction-rtl) {
        padding-left: 9em;
      }
      li.ql-indent-3:not(.ql-direction-rtl) {
        padding-left: 10.5em;
      }
      .ql-indent-4:not(.ql-direction-rtl) {
        padding-left: 12em;
      }
      li.ql-indent-4:not(.ql-direction-rtl) {
        padding-left: 13.5em;
      }
      .ql-indent-5:not(.ql-direction-rtl) {
        padding-left: 15em;
      }
      li.ql-indent-5:not(.ql-direction-rtl) {
        padding-left: 16.5em;
      }
      .ql-indent-6:not(.ql-direction-rtl) {
        padding-left: 18em;
      }
      li.ql-indent-6:not(.ql-direction-rtl) {
        padding-left: 19.5em;
      }
      .ql-indent-7:not(.ql-direction-rtl) {
        padding-left: 21em;
      }
      li.ql-indent-7:not(.ql-direction-rtl) {
        padding-left: 22.5em;
      }
      .ql-indent-8:not(.ql-direction-rtl) {
        padding-left: 24em;
      }
      li.ql-indent-8:not(.ql-direction-rtl) {
        padding-left: 25.5em;
      }
      .ql-indent-9:not(.ql-direction-rtl) {
        padding-left: 27em;
      }
      li.ql-indent-9:not(.ql-direction-rtl) {
        padding-left: 28.5em;
      }
      ol,
      ul {
        padding-left: 0.5em;
        li:not(.ql-direction-rtl) {
          padding-left: 0.5em;
        }
      }
      ol > li,
      ul > li {
        list-style-type: none;
      }
      ul > li::before {
        content: "\2022";
        display: inline-block;
        white-space: nowrap;
        width: 1.2em;
      }
      ol li {
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
          list-9;
        counter-increment: list-0;
      }
      ol li:before {
        content: counter(list-0, decimal) ". ";
      }
    }
  }
}
